import React, { useState } from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Sidebar from 'react-sidebar';

import logo from '../../../static/credfinshots.png';

const Header = (props) => {
    const { site } = props;
    const [menuState, setMenuOpen] = useState({ menuOpen: false });

    const closeMenu = () => {
        setMenuOpen({ menuOpen: false });
    };

    return (
        <div style={{ zIndex: 5 }}>
            <header className="site-head">
                <div className="header">
                    <div className="site-mast">
                        <div className="site-mast-left">
                            <Link to="/">
                                <img
                                    className="site-logo"
                                    src={logo}
                                    alt={site.title}
                                />
                            </Link>
                        </div>
                        <div className="site-mast-right">
                            <div className="site-nav-item">
                                <AnchorLink to="/#daily">
                                    Past Issues
                                </AnchorLink>
                            </div>

                            <div className="site-nav-item">
                                <a
                                    href={encodeURI(
                                        'https://finshots.in/top-articles?utm_source=finshotsCred&utm_medium=webHeader'
                                    )}
                                >
                                    Top Articles
                                </a>
                            </div>
                            <div className="site-nav-item">
                                <a
                                    href={encodeURI(
                                        'https://finshots.in/infographic?utm_source=finshotsCred&utm_medium=webHeader'
                                    )}
                                >
                                    Infographics
                                </a>
                            </div>
                        </div>

                        <div className="site-mast-right-mobile">
                            <img
                                src="/images/hamburger.png"
                                alt="hamburger"
                                onClick={() => {
                                    setMenuOpen({ menuOpen: true });
                                }}
                            />
                        </div>
                    </div>

                    <Sidebar
                        sidebar={
                            <div className="side-menu">
                                <div
                                    onClick={() => {
                                        setMenuOpen({ menuOpen: false });
                                    }}
                                >
                                    <div
                                        className="bm-cross-button"
                                        style={{
                                            position: 'absolute',
                                            width: 24,
                                            height: 24,
                                            right: 8,
                                            top: 8
                                        }}
                                    >
                                        <span
                                            style={{
                                                position: 'fixed',
                                                top: '6px',
                                                right: '14px'
                                            }}
                                        >
                                            <span
                                                className="bm-cross"
                                                style={{
                                                    position: 'absolute',
                                                    width: '3px',
                                                    height: '14px',
                                                    transform: 'rotate(45deg)'
                                                }}
                                            ></span>
                                            <span
                                                className="bm-cross"
                                                style={{
                                                    position: 'absolute',
                                                    width: '3px',
                                                    height: '14px',
                                                    transform: 'rotate(-45deg)'
                                                }}
                                            ></span>
                                        </span>
                                        <button
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                top: 0,
                                                width: '100%',
                                                height: '100%',
                                                margin: 0,
                                                padding: 0,
                                                border: 'none',
                                                fontSize: 0,
                                                background: 'transparent',
                                                cursor: 'pointer'
                                            }}
                                            tabIndex="0"
                                        >
                                            Close Menu
                                        </button>
                                    </div>
                                </div>
                                <Link
                                    to="/#daily"
                                    onClick={() => closeMenu()}
                                    className="bm-item"
                                >
                                    Past Issues
                                </Link>

                                <a
                                    href={encodeURI(
                                        'https://finshots.in/top-articles?utm_source=finshotsCred&utm_medium=webHeader'
                                    )}
                                    className="bm-item"
                                >
                                    Top Articles
                                </a>

                                <a
                                    className="bm-item"
                                    href={encodeURI(
                                        'https://finshots.in/infographic?utm_source=finshotsCred&utm_medium=webHeader'
                                    )}
                                >
                                    Infographics
                                </a>
                            </div>
                        }
                        open={menuState.menuOpen}
                        onSetOpen={(open) => {
                            setMenuOpen({ menuOpen: open });
                        }}
                        styles={{
                            sidebar: {
                                background: 'white',
                                width: 300
                            },
                            root: {
                                position: menuState.menuOpen
                                    ? 'fixed'
                                    : 'absolute',
                                visibility: !menuState.menuOpen
                                    ? 'hidden'
                                    : 'visible'
                            }
                        }}
                        pullRight
                    />
                </div>
            </header>
        </div>
    );
};

export default Header;
