import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import siteConfig from '../../utils/siteConfig';

const FinshotsDailySection = () => {
    const contactRef = useRef('contact');
    return (
        <div className="SectionMainWrapper" ref={contactRef}>
            <div className="container-daily-finshots">
                <div className="containerClass">
                    <h1 className="h1">
                        Join {siteConfig.subScriberCount} subscribers
                    </h1>
                    <span style={{ fontSize: `1.1em` }}>
                        Just one article every day. Pinky promise 🙌
                    </span>
                    <div className="form-wrapper">
                        <form
                            className="contact-form"
                            target="_blank"
                            method="post"
                            action="https://finshots.us18.list-manage.com/subscribe/post?u=4586001b135729fc977641e60&id=5356ccb39e"
                        >
                            <div className="email-input">
                                <div className="field-wrapper">
                                    <input
                                        type="email"
                                        placeholder="Enter Your Email Address"
                                        name="EMAIL"
                                        aria-label="email"
                                    />
                                </div>
                            </div>
                            <div hidden>
                                <input
                                    type="text"
                                    name="b_4586001b135729fc977641e60_5356ccb39e"
                                    tabIndex="-1"
                                    readOnly=""
                                />
                            </div>
                            <button
                                className="button"
                                type="submit"
                                id="mc-embedded-subscribe"
                                name="subscribe"
                            >
                                SUBSCRIBE
                            </button>
                        </form>
                    </div>
                    <span style={{ fontSize: `1.2em` }}>
                        Be a part of our ever growing community.{' '}
                        <a
                            target="_blank"
                            href="https://twitter.in/finshots"
                            rel="noreferrer"
                        >
                            Join us on Twitter
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

FinshotsDailySection.propTypes = {
    sectionWrapper: PropTypes.object,
    secTitleWrapper: PropTypes.object,
    row: PropTypes.object,
    contactForm: PropTypes.object,
    secHeading: PropTypes.object,
    secText: PropTypes.object,
    button: PropTypes.object,
    note: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
    colornote: PropTypes.object
};

FinshotsDailySection.defaultProps = {
    sectionWrapper: {
        id: 'contact_section',
        as: 'section',
        pt: ['8px', '80px', '80px', '80px'],
        pb: ['0', '80px', '80px', '80px', '80px']
    },
    secTitleWrapper: {
        mb: ['0px', '0px', '0px'],
        p: ['0 15px', 0, 0, 0, 0]
    },
    secText: {
        as: 'span',
        display: 'block',
        textAlign: 'center',
        fontSize: `${2}`,
        letterSpacing: '0.15em',
        fontWeight: `${6}`,
        color: 'primary',
        mb: `${3}`
    },
    secHeading: {
        textAlign: 'center',
        fontSize: [`${6}`, `${8}`],
        fontWeight: '400',
        color: 'headingColor',
        letterSpacing: '-0.025em',
        mb: `${0}`
    },
    row: {
        flexBox: true,
        justifyContent: 'center'
    },
    contactForm: {
        width: [1, 1, 1, 1 / 2]
    },
    button: {
        type: 'button',
        fontSize: `${2}`,
        fontWeight: '600',
        borderRadius: '4px',
        pl: '22px',
        pr: '22px',
        colors: 'primaryWithBg',
        height: `${4}`
    },
    note: {
        fontSize: '16px',
        fontWeight: '400',
        color: '#525f7f',
        lineHeight: '28px',
        mb: ['25px', '25px', '30px', '30px', '45px'],
        textAlign: ['center', 'center']
    },
    colornote: {
        fontSize: '16px',
        fontWeight: '500',
        color: 'rgb(106, 82, 253)',
        lineHeight: '28px',
        mb: ['25px', '25px', '30px', '30px', '45px'],
        textAlign: ['center', 'center']
    },
    title: {
        content: `Join ${siteConfig.subScriberCount} subscribers`,
        fontSize: ['20px', '26px', '30px', '36px', '40px'],
        lineHeight: ['30px', '32px', '40px', '50px', '55px'],
        fontWeight: '700',
        color: '#32325d',
        letterSpacing: '-0.010em',
        mb: '20px',
        textAlign: ['center', 'center']
    },

    description: {
        content: 'We will never spam you. Pinky promise 🙌',
        fontSize: '16px',
        fontWeight: '400',
        color: '#525f7f',
        lineHeight: '28px',
        mb: ['25px', '25px', '30px', '30px', '45px'],
        textAlign: ['center', 'center']
    }
};

export default FinshotsDailySection;
