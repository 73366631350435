import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { readingTime as readingTimeHelper } from '@tryghost/helpers';
import dateformat from 'dateformat';

const PostCard = ({ post, topArticles }) => {
    const url = `/${post.slug}/`;
    const readingTime = readingTimeHelper(post);

    return (
        <article className="post-card">
            {topArticles ? (
                <a
                    href={
                        'https://finshots.in/archive' +
                        url +
                        '?utm_source=finshotsCred&utm_medium=readNext'
                    }
                    className="post-card-image-link"
                    target="_blank"
                >
                    {post.feature_image && (
                        <img
                            className="post-card-image"
                            src={post.feature_image}
                            sizes="(max-width: 1000px) 400px, 700px"
                            alt={post.title}
                        />
                    )}
                </a>
            ) : (
                <Link to={url} className="post-card-image-link">
                    {post.feature_image && (
                        <img
                            className="post-card-image"
                            src={post.feature_image}
                            sizes="(max-width: 1000px) 400px, 700px"
                            alt={post.title}
                        />
                    )}
                </Link>
            )}
            {topArticles ? (
                <a
                    href={
                        'https://finshots.in/archive' +
                        url +
                        '?utm_source=finshotsCred&utm_medium=readNext'
                    }
                    className="post-card-content-link"
                    target="_blank"
                >
                    <header className="post-card-header">
                        <h2 className="post-card-title">{post.title}</h2>
                    </header>
                    <section className="post-card-excerpt">
                        {post.excerpt}
                    </section>
                    <footer className="post-card-footer">
                        <div className="post-card-footer-left">
                            <div>
                                {dateformat(
                                    new Date(post.published_at),
                                    `mmmm dS, yyyy`
                                )}
                            </div>
                        </div>
                        <div className="post-card-footer-right reading-time">
                            <div>{readingTime}</div>
                        </div>
                    </footer>
                </a>
            ) : (
                <Link to={url} className="post-card-content-link">
                    <header className="post-card-header">
                        <h2 className="post-card-title">{post.title}</h2>
                    </header>
                    <section className="post-card-excerpt">
                        {post.excerpt}
                    </section>
                    <footer className="post-card-footer">
                        <div className="post-card-footer-left">
                            <div>
                                {dateformat(
                                    new Date(post.published_at),
                                    `mmmm dS, yyyy`
                                )}
                            </div>
                        </div>
                        <div className="post-card-footer-right reading-time">
                            <div>{readingTime}</div>
                        </div>
                    </footer>
                </Link>
            )}
        </article>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        published_at: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string
        }).isRequired
    }).isRequired,
    topArticles: PropTypes.bool
};

export default PostCard;
