import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Landing = () => (
    <div className="landing">
        <div className="landing-first-half">
            <div>
                <h1>Financial News Made Simple</h1>
                <span>
                    Join Finshots and get your daily dose of the latest, most
                    important Financial developments delivered in plain English.
                    In less than 3 minutes.
                </span>
                <br />
                <AnchorLink style={{ fontFamily: `Roboto` }} to="/#daily">
                    <button>Read Now</button>
                </AnchorLink>
            </div>
        </div>
        <div style={{ width: '4%' }}></div>
        <div className="landing-second-half">
            <div style={{ position: 'relative', left: 0, top: 0 }}>
                <img
                    className="phone-background"
                    src="/images/landingFold.png"
                    alt="phone background"
                />
                <img
                    className="phone"
                    src="/images/phonedaily.png"
                    alt="phone"
                />
            </div>
        </div>
    </div>
);

const LandingMarkets = () => (
    <div className="landing">
        <div className="landing-first-half">
            <div>
                <h1>Market News made simple</h1>
                <span>
                    Every week we bring you the most important developments from
                    the Indian markets in a language you can understand. Simple.
                    No Nonsense. To the point. That's the promise.
                </span>
                <br />
                <AnchorLink
                    style={{ fontFamily: `Roboto` }}
                    to="/past-issues/#past_issues"
                >
                    <button>Past Issues</button>
                </AnchorLink>
            </div>
        </div>
        <div style={{ width: '4%' }}></div>
        <div className="landing-second-half">
            <div style={{ position: 'relative', left: 0, top: 0 }}>
                <img
                    className="phone-background"
                    src="/images/landingFold.png"
                    alt="phone background"
                />
                <img className="phone" src="/images/phone.png" alt="phone" />
            </div>
        </div>
    </div>
);

export { Landing, LandingMarkets };
